/* CSS Rules */

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS Variables */
:root {
    /* Font Sizes */
    --heading: 24px;
    --subheading: 20px;
    --body: 16px;

    /* Colours */
    --teal: #00a7a4;
    --darkteal: #006866;
    --orange: #FA8836;
    --darkred: #DE0000;

}

/* Rule - Images should never be rendered at a size wider than their containing element using max-inline-size */
img {
    max-inline-size: 100%;
    block-size: auto;
}

/* ------------------------------------------------------ */
/*  Scroll Bars                                           */
/* ------------------------------------------------------ */

/* bar width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: 1px solid #D8D8D8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* ------------------------------------------------------ */
/*  Primary Button                                        */
/*  Purpose: Use this for a call to action button         */
/* ------------------------------------------------------ */

.Primary-Button {
    background-color: #00A7A4;
    border: 1.5px solid #00A7A4;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0px 5px 0px;
    font-weight: 600;
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
    font-size: var(--body);
    min-width: 100px;
    cursor: pointer;
}

.Primary-Button:disabled {
    background-color: #E9EBED;
    border: 1.5px solid #E9EBED;
    color: #9BA7B6;
    cursor: default;
}

.Primary-Button:enabled:hover {
    background-color: #028E8C;
    border: 1.5px solid #028E8C;
    transform: scale(1.025);
}

.Primary-Button:enabled:focus {
    background-color: #006866;
    border: 1.5px solid #006866;
}

/* ------------------------------------------------------ */
/** Secondary Button                                     **/
/*  Purpose: Use this secondary action, e.g. cancel       */
/* ------------------------------------------------------ */

.Secondary-Button {
    border: 1.5px solid #00A7A4;
    background-color: white;
    box-sizing: border-box;
    color: #00A7A4;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    margin: 5px 0px;
    cursor: pointer;
    font-size: var(--body);
    min-width: 100px;
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
}

.Secondary-Button:disabled {
    border: 1.5px solid #9BA7B6;
    color: #9BA7B6;
    cursor: default;
}

.Secondary-Button:hover {
    transform: scale(1.025);
}

.Secondary-Button:active {
    border: 1.5px solid #028E8C;
    background-color: #028E8C;
    color: white;
}


/* ------------------------------------------------------------ */
/** Link Button                                                **/
/*  Purpose: Use this for any page link buttons                 */
/* ------------------------------------------------------------ */

.Link-Button {
    color: #00A7A4;
    font-size: var(--body);
    font-weight: 600;
    line-height: 30px;
    text-decoration-line: underline;
    text-align: center;
}

.Link-Button:hover {
    background-color: #535353;
    color: white;
}

.Link-Button:disabled {
    color: #9BA7B6;
    cursor: default;
}

.Link-Button:enabled:hover {
    color: #028E8C;
}

.Link-Button:enabled:focus {
    color: #006866;
}

/* ------------------------------------------------------ */
/*  Warning Button                                        */
/*  Purpose: Use this for warning modals                  */
/* ------------------------------------------------------ */

.Warning-Button {
    background-color: #C4314B;
    border: 1.5px solid #C4314B;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0px 5px 0px;
    font-weight: 600;
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
    font-size: var(--body);
    min-width: 100px;
    cursor: pointer;
}

.Warning-Button:disabled {
    background-color: #E9EBED;
    border: 1.5px solid #E9EBED;
    color: #9BA7B6;
    cursor: default;
}

.Warning-Button:enabled:hover {
    background-color: #A72940;
    border: 1.5px solid #A72940;
    transform: scale(1.025);
}

.Warning-Button:enabled:focus {
    background-color: #942237;
    border: 1.5px solid #942237;
}

/* Warning-Secondary-Button */

.Warning-Secondary-Button {
    background-color: white;
    border: 1.5px solid #C4314B;
    color: #C4314B;;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0px 5px 0px;
    font-weight: 600;
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
    font-size: var(--body);
    min-width: 100px;
    cursor: pointer;
}

.Warning-Secondary-Button:disabled {
    background-color: #E9EBED;
    border: 1.5px solid #E9EBED;
    color: #9BA7B6;
    cursor: default;
}

.Warning-Secondary-Button:hover {
    transform: scale(1.025);
}


/* ---------------------------------------------------------------- */
/** Icon Button                                                    **/
/*  Purpose: Use this secondary action with icon, e.g. cancel       */
/* ---------------------------------------------------------------- */

.Icon-Button {
    border: 1.5px solid #00A7A4;
    background-color: white;
    box-sizing: border-box;
    color: #00A7A4;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    margin: 5px 0px;
    cursor: pointer;
    font-size: var(--body);
    min-width: 100px;
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.Icon-Button:disabled {
    border: 1.5px solid #9BA7B6;
    color: #9BA7B6;
    cursor: default;
}

.Icon-Button:hover {
    background-color: #00A7A4;
    color: white;
}

.Icon-Button:active {
    border: 1.5px solid #028E8C;
    background-color: #028E8C;
    color: white;
}


/* ------------------------------------------------------ */
/*  Form Styling                                          */
/* ------------------------------------------------------ */

.Form {
    min-width: 250px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 3px;
    padding: 40px;
    font-weight: 600;
}

.Form-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    font-size: var(--heading);
}

.Form.Group {
    margin: 15px 10px;
    padding: 1.5em;
}

/* ----- Field Styling ----- */

.Label-Field-Title {
    margin: 25px 0px 0px 0px;
    font-size: var(--body);
}

.Label-Field-Value {
    font-size: var(--body);
    color: #929292;
}

/* ------------------------------------------------ */
/*  Table Styling                                   */
/* ------------------------------------------------ */

.Table-Container {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    text-align: left;
    /* border: 0.5px solid #daddd7;
    border-radius: 3px; */
    padding: 0px;
    margin: 0px;
    border-radius: 10px;
    border: 1.5px solid var(--neutral-colours-e-9-ebed-default-background-disabled, #D8D8D8);
}

.Table-th {
    padding: 20px 50px;
    font-weight: 600;
    border-bottom: 0.5px solid lightgray;
}

.Table-td {
    padding: 20px 50px;
    word-wrap: break-word;
    border-bottom: 0.5px solid lightgray;
}


/* ---------------------------------------------- */
/*  Modal Background                              */
/* ---------------------------------------------- */

.Modal-Background {
    background-color: rgba(0, 0, 0, 0.716);
    z-index: 20;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    top: 12%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-top: 15px solid var(--teal);
    overflow: auto;
    padding: 2%;
}

/* ============================================== */
/*  Default Page Container                        */
/* ============================================== */

.PageComponent-Container {
    display: grid;
    grid-template-areas:
        'Header'
        'Body';
    padding: 0px;
    margin: 0px;
    gap: 0px;
}

.PageComponent-Header {
    grid-area: Header;

}

.PageComponent-Body {
    grid-area: Body;
    margin: 0px;
}

.PageComponent-AccessDenied {
    text-align: center;
    margin-top: 1%;
}

.PageComponent-AccessDenied img {
    max-width: 150px;
}

.PageComponent-Errors-Container {
    text-align: center;
    padding: 1%;
}

.PageComponent-Errors-Container img {
    width: 15%;
}

.PageComponent-Errors-Container p {
    line-height: 1.7;
}


/* ----------------------------------------------------------- */
/*  Header Container                                           */
/*  - Use this for your page header                            */
/* ----------------------------------------------------------- */

.Page-Header-Container {
    border-bottom: 1px solid #D8D8D8;
    background: #F5F5F5;
    padding: 30px 50px;
}

/* ----------------------------------------------------------- */
/*  Section Container                                          */
/*  - Use this for sections on your page                       */
/* ----------------------------------------------------------- */

.Page-Section-Container {
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px 0px rgba(65, 77, 92, 0.20), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);
    padding: 2%;
    margin-bottom: 2%;
}

.Page-Body-Container {
    padding: 2% 4%;
}

/* ------------------------------------------------------ */
/*  Input Field (Text)                                    */
/* ------------------------------------------------------ */

.Input-Field-Text {
    font-size: var(--body);
    height: 42px;
    width: 450px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    background-color: white;
    padding: 20px 15px;
}

.Input-Field-Text:disabled {
    color: #2a2a2a;
    background-color: #f8f8f8;
}

.Input-Field-Text:active {
    outline: 1px #35394e7e solid;
}

.Input-Field-Text:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-Text:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-Text-Error {
    height: 42px;
    width: 450px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    box-sizing: border-box;
    padding: 15px;
}

/* Multi Select */

.Input-Field-MultiSelect {
    font-size: var(--body);
    color: #212529;
    height: 100px;
    width: 400px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
    font-weight: normal;
}

.Input-Field-MultiSelect:disabled {
    background-color: #f8f8f8;
}

.Input-Field-MultiSelect:disabled option {
    color: #2a2a2a;
}

.Input-Field-MultiSelect:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-MultiSelect:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-MultiSelect-Error {
    font-size: var(--body);
    height: 100px;
    width: 400px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    padding: 10px;
}

.Input-Field-MultiSelect-Error:active {
    outline: 1px solid var(--darkred);
}

/* ------------------------------------ */
/*  Select Box                          */
/* ------------------------------------ */

.Input-Field-Select {
    font-size: var(--body);
    color: #212529;
    height: 44px;
    width: fit-content;
    min-width: 300px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
    font-weight: normal;
}

.Input-Field-Select option {
    color: #212529;
}

.Input-Field-Select:active {
    outline: 1px #35394e7e solid;
}

.Input-Field-Select:disabled {
    color: #2a2a2a;
    background-color: #f8f8f8;
}

.Input-Field-Select:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-Select:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-Select-Error {
    font-size: var(--body);
    height: 42px;
    width: 150px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    padding: 10px;
}

.Input-Field-Select-Error:active {
    outline: 1px solid var(--darkred);
}

.Input-Field-Select-Error:focus {
    outline: 1px solid var(--darkred);
}

/* Text Area  */

.Input-Field-TextArea {
    font-size: var(--body);
    height: 100px;
    width: 100%;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    color: black;
    padding: 10px;
    resize: none;
}

.Input-Field-TextArea:disabled {
    color: #2a2a2a;
    background-color: #f8f8f8;
}

.Input-Field-TextArea:disabled:active {
    outline: none;
}

.Input-Field-TextArea:active {
    outline: none;
}

.Input-Field-TextArea:focus {
    outline: none;
}

.Input-Field-TextArea:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-TextArea-Error {
    font-size: var(--body);
    height: 200px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    box-sizing: border-box;
}

.Input-Field-TextArea-Error:active {
    outline: 1px solid var(--darkred);
}

.Input-Field-TextArea-Error:focus {
    outline: 1px solid var(--darkred);
}

.Label-List-Item {
    display: flex;
    align-items: center;
    font-size: var(--body);
    border-radius: 5px;
    border: 1px #E3F6F5 solid;
    background-color: #E3F6F5;
    height: 25px;
    width: 100%;
    margin: 5px;
    padding: 20px;
}

.Input-Field-MultiSelect {
    font-size: var(--body);
    color: #212529;
    height: 100px;
    width: 400px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
    font-weight: normal;
}

.Input-Field-MultiSelect:disabled {
    background-color: #f8f8f8;
}

.Input-Field-MultiSelect:disabled option {
    color: #2a2a2a;
}

.Input-Field-Radio {
    height: 25px;
    width: 25px;
    margin: 10px;
    cursor: pointer;
}

.Input-Icon {
    width: 35px;
    cursor: pointer;
}

.Input-Field-Text-ReadOnly {
    font-size: var(--body);
    height: 25px;
    width: 450px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    background-color: #f8f8f8;
    font-weight: normal;
}

.Input-Field-TextArea-ReadOnly {
    font-size: var(--body);
    height: 100px;
    width: 100%;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    color: black;
    padding: 10px;
    resize: none;
    background-color: #f8f8f8;
    font-weight: normal;
}

/* ------------------------------------------------ */
/*  Radio Buttons                                   */
/* ------------------------------------------------ */

.Radio-Buttons-Container {
    display: block;
    position: relative;
    padding: 0px 5px 0px 35px;
    margin-left: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.Radio-Buttons-Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.Radio-Checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1.5px solid #9F9C9C;
    border-radius: 50%;
}

/* Error State for a Radio Button */
.Radio-Checkmark-Error {
    position: absolute;
    top: 0;
    left: 0px;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1.5px solid var(--darkred);
    border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Radio-Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.Radio-Buttons-Container input:checked~.Radio-Checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.Radio-Buttons-Container .Radio-Checkmark:after {
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--teal);
}

/* ---------------------------------------------- */
/*  Pane Background                              */
/* ---------------------------------------------- */

.Pane-Background {
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 100%;
}

@keyframes slide {
    0%   {
        left:calc(100vw - 10vw);
    }
    100% {
        right:calc(50vw - 100vw);
    }
}

.Pane-Container {
    position: absolute;
    width: 50vw;
    height: 100vh;
    top: 0px;
    right: calc(50vw - 100vw);
    animation-name: slide;
    animation-duration: 4s;
    display: flex;
    gap: 15px 0px;
    border-top-left-radius: 5px;
    z-index: 1000;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-top: 15px solid var(--teal);
    overflow: auto;
    padding: 2%;
    animation: slide;
    animation-duration: .5s;
    animation-timing-function: ease-in;
}

/* ------------------------------------------------ */
/* Form Layout & Styling                            */                           
/* ------------------------------------------------ */

/* Form Layout & Styling */
.FormComponent {
    display: grid;
    row-gap: 10px;
    font-size: 16px;
}

.FormComponent-Row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}

.FormComponent-Row label {
    margin: 10px 0px;
}

.FormComponent-Row input {
    padding: 20px 15px;
    color: black;
}

.FormComponent-Row textarea {
    padding: 15px;
    color: black;
}

.FormComponent-Row select { 
    color: black;
}

.FormComponent-Radio {
    display: flex;
    align-items: center;
}

/* Project Owners Classes */
.FormComponent-ProjectOwners {
    display: grid;
    grid-template-columns: 500px;
    align-items: center;
    row-gap: 10px;
}

.FormComponent-New {
    background-color: rgba(132, 193, 212, 0.19);
    padding: 20px;
    border-radius: 5px;
}

.Expandable-Section-Container {
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px 0px rgba(65, 77, 92, 0.20), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);
    margin: 0%;
}

/* ---------------------------------------------- */
/*  Modal Background                              */
/* ---------------------------------------------- */

.Modal-Background {
    background-color: rgba(0, 0, 0, 0.716);
    z-index: 20;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    top: 25%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-top: 15px solid var(--teal);
    overflow: auto;
    padding: 2%;
    max-width: 60%;
    max-height: 75%;
}

.Warning-Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    border-radius: 5px;
    z-index: 1000;
    position: absolute;
    top: 25%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #C4314B;
    overflow: auto;
    padding: 2%;
    max-width: 60%;
    max-height: 75%;
}

/* ----------------------------------------- */
/*  Filter Buttons                           */
/* ----------------------------------------- */

.Filter-Primary {
    white-space: nowrap;
    border: solid 1.5px var(--teal);
    color: white;
    font-weight: 600;
    background-color: var(--teal);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0;
    font-size: var(--body);
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
}

.Filter-Secondary {
    white-space: nowrap;
    border: solid 1.5px #D8D8D8;
    color: #3f3f3f;
    font-weight: 600;
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0;
    font-size: var(--body);
    box-shadow: 0px 1px 1px rgba(196, 196, 196, 0.5);
    cursor: pointer;
}

/* ================================================ */
/*   Attach File                                    */
/* ================================================ */

/* Onload Container */
.Attach-File-Onload-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0px 15px;
}

.Attach-File-Onload-Container input {
    width: 100%;
    height: 40px;
}

/* 'Choose File' (inside input [file] */

.Attach-File-Onload-Container input::-webkit-file-upload-button {
    content: 'Choose File';
    margin: 0px 15px 0px 0px;
    height: 40px;
    border: none;
    border-right: 1px #D8D8D8 solid;
    color: var(--teal);
    padding: 5px 15px;
    cursor: pointer;
    background-color: white;
    text-transform: capitalize;
    font-weight: 500;
}

/* Error Message */
.Attach-File-Error-Message {
    color: red;
    text-align: left;
}


/* --------------------------------------------------- */
/*  Checkboxes                                         */
/* --------------------------------------------------- */

/* Checkbox Container */
.Checkbox-Container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.Checkbox-Container input {
    display: none;
}

/* Create a custom checkbox */
.Checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1.5px solid #C0C0C0;
    border-radius: 2px;
}

/* Error State for Checkbox  */
.Checkbox-Error {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1.5px solid var(--darkred);
    border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.Checkbox-Container input:checked~.Checkbox {
    background-color: var(--teal);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Checkbox-Container input:checked~.Checkbox:after {
    display: block;
}

/* Style the checkmark/indicator */
.Checkbox-Container .Checkbox:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}