/* ------------------------------------------------------------ */
/** App Styling                                                **/
 /* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

@media (width >= 931px) {

    .App-Container{
        display: grid;
        grid-template-rows: 70px calc(100vh - 70px);
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
    }
    
}

@media (width <= 930px) {

    .App-Container{
        display: grid;
        grid-template-rows: 70px calc(100vh - 70px);
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
    }
    
}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    width: 100%;
}

.App-Container-Body{
    grid-area: App-Container-Body;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}